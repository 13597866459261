import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AllLayout } from "./screens/AllLayout";
import MainHomePage from "./screens/homepage/main";
import './index.css';

const App = () => {
  useEffect(() => {}, []);

  return (
    <Routes>
      <Route path="/" element={<AllLayout />}>
        <Route path="/" element={<MainHomePage />} />
      </Route>
    </Routes>
  );
};

export default App;