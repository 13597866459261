import React from "react";
import styled from "styled-components";

const MainHomePage = () => {
  return (
    <Style>
      <div className="wrap">
        <img
          className="banner"
          src={`${process.env.PUBLIC_URL}/img/ivid.jpg`}
          alt="홈페이지 준비중"
        />
      </div>
    </Style>
  );
};

export default MainHomePage;

const Style = styled.div`
  .wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100vh;
  }

  @media (max-width: 900px) {
    .banner {
      content: url(${process.env.PUBLIC_URL}/img/m_ivid.png);
    }
  }
`;
